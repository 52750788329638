<template>
  <ModuleComponent
    ref="moduleComponent"
    @changed="item = $event"
    :module-data="moduleData"
    @beforeSaveItem="beforeSaveItem"
    @beforeItemLoad="beforeItemLoad"
  >
    <template v-slot:customColumn_active="{ rowItem }">
      <TableStatusColumn v-model="rowItem.active"></TableStatusColumn>
    </template>
    <template v-slot:tableFilters="{ filters }">
      <b-container fluid>
        <b-row>
          <b-col cols="3" sm="3" md="3">
            <b-input-group prepend="@">
              <b-form-input
                v-model="item.email"
                type="email"
                :placeholder="t('GLOBAL.EMAIL')"
              ></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <template v-slot:form="{ item }">
      <b-tabs class="mt-3">
        <b-tab :title="t('GLOBAL.INFO')" active>
          <b-container fluid>
            <b-row>
              <b-col cols="12" sm="4" md="4">
                <b-form-group :label="t('GLOBAL.EMAIL')">
                  <b-input-group prepend="@">
                    <b-form-input
                      v-model="item.email"
                      name="email"
                      type="email"
                      :placeholder="t('GLOBAL.EMAIL')"
                      v-validate.continues="'required|email'"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="4" md="4">
                <b-form-group :label="t('GLOBAL.PASSWORD')">
                  <b-input-group prepend="#">
                    <b-form-input
                      v-model="item.password"
                      name="password"
                      ref="password"
                      type="password"
                      :placeholder="t('GLOBAL.PASSWORD')"
                      v-validate="'required'"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="4" md="4">
                <b-form-group :label="t('GLOBAL.PASSWORDCONFIRM')">
                  <b-input-group prepend="#">
                    <b-form-input
                      type="password"
                      name="password_confirmation"
                      :placeholder="t('GLOBAL.PASSWORDCONFIRM')"
                    ></b-form-input>
                  </b-input-group>
                  <span
                    v-show="validationErrors.has('password_confirmation')"
                    class="help is-danger"
                    >{{ validationErrors.first("password_confirmation") }}</span
                  >
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="4" md="4">
                <b-form-group :label="t('GLOBAL.IPADDRESS.P')">
                  <b-form-input v-model="item.ips"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6" sm="6" md="4">
                <b-form-group :label="t('GLOBAL.COMPANIES')">
                  <v-select2
                    v-model="companies"
                    endPoint="companies"
                    :placeholder="t('GLOBAL.COMPANIES')"
                    multiple
                  ></v-select2>
                </b-form-group>
              </b-col>
              <b-col cols="2" sm="3" md="2">
                <b-form-group :label="t('GLOBAL.STATUS')">
                  <b-form-checkbox v-model="item.active" switch>
                    {{ t("GLOBAL.ACTIVE") }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-tab>
        <b-tab :title="t('GLOBAL.ENDPOINTS')">
          <b-container fluid>
            <b-button @click="togglePermissions" class="mr-1" size="sm"
              >Toggle Permissions
            </b-button>
            <b-button @click="setAll(true)" class="mr-1" size="sm"
              >Tümüne izin ver
            </b-button>
            <b-button @click="setAll(false)" class="mr-1" size="sm"
              >Tüm izinleri kaldır
            </b-button>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">{{ t("GLOBAL.ENDPOINT") }}</th>
                    <th class="text-center"></th>
                    <th class="text-center">{{ t("GLOBAL.GET") }} (GET)</th>
                    <th class="text-center">{{ t("GLOBAL.POST") }} (POST)</th>
                    <th class="text-center">{{ t("GLOBAL.PUT") }} (PUT)</th>
                    <th class="text-center">
                      {{ t("GLOBAL.DELETE") }} (DELETE)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(p, index) in permissionsMap" :key="p.endpointId">
                    <td v-if="p.endpoint">{{ p.endpoint.name }}</td>
                    <td class="text-center">
                      <b-button size="sm" @click="setRow(index, p.get)">
                        Tümünü Aç/Kapa
                      </b-button>
                    </td>
                    <td class="text-center">
                      <b-form-checkbox
                        v-model="p.get"
                        @change="setPermission(index, !p.get)"
                        switch
                        >GET
                      </b-form-checkbox>
                    </td>
                    <td class="text-center">
                      <b-form-checkbox
                        v-model="p.post"
                        switch
                        :disabled="!p.get"
                        >POST
                      </b-form-checkbox>
                    </td>
                    <td class="text-center">
                      <b-form-checkbox v-model="p.put" switch :disabled="!p.get"
                        >PUT
                      </b-form-checkbox>
                    </td>
                    <td class="text-center">
                      <b-form-checkbox
                        v-model="p.delete"
                        switch
                        :disabled="!p.get"
                        >DELETE
                      </b-form-checkbox>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </b-container>
        </b-tab>
      </b-tabs>
    </template>
  </ModuleComponent>
</template>

<script>
// http://core.datadns.net:5000/swagger/index.html#model-ApiUser
export default {
  name: "apiUsers",
  data() {
    return {
      item: {},
      moduleData: {
        name: "apiUsers",
        primaryKey: "id",
        table: {
          sortBy: "email",
          sortDesc: false,
          graphqlQuery: `id,email,active`,
          headers: [
            { text: "ID", value: "id", width: "70" },
            { text: this.t("GLOBAL.EMAIL"), value: "email" },
            { text: this.t("GLOBAL.IPADDRESS.P"), value: "ips" },
            {
              text: this.t("GLOBAL.STATUS"),
              value: "active",
              width: "60",
              align: "center"
            }
          ],
          customColumns: ["active"],
          filters: {
            name: {
              type: "string",
              searchBy: "beginsWith",
              value: null
            }
          }
        }
      },
      allEndpoints: [],
      permissionsMap: [],
      companies: []
    };
  },
  methods: {
    setRow(index, status) {
      this.permissionsMap[index].get = !status;
      this.permissionsMap[index].post = !status;
      this.permissionsMap[index].put = !status;
      this.permissionsMap[index].delete = !status;
    },
    setPermission(index, status) {
      this.permissionsMap[index].post = !status;
      this.permissionsMap[index].put = !status;
      this.permissionsMap[index].delete = !status;
    },
    togglePermissions() {
      this.permissionsMap.forEach(p => {
        p.get = !p.get;
        p.delete = !p.delete;
        p.post = !p.post;
        p.put = !p.put;
      });
    },
    setAll(status) {
      this.permissionsMap.forEach(p => {
        p.get = status;
        p.delete = status;
        p.post = status;
        p.put = status;
      });
    },
    beforeSaveItem(item) {
      item.companies = this.companies.map(p => {
        return { apiUserId: item.id, companyId: p };
      });
      item.endpoints = this.permissionsMap.map(p => {
        delete p["endpoint"];
        return p;
      });
    },
    beforeItemLoad(item) {
      this.companies = item.companies.map(p => {
        return p.companyId;
      });
      this.permissionsMap = this.getPermissions(item);
    },
    getPermissions(item) {
      let endpoints = [];
      for (const i in this.allEndpoints) {
        let endpoint = this.allEndpoints[i];
        let found = false;
        for (const j in item.endpoints) {
          if (item.endpoints[j].endPointId == endpoint.id) {
            endpoints.push(item.endpoints[j]);
            found = true;
            break;
          }
        }
        if (!found) {
          endpoints.push({
            endPointId: endpoint.id,
            roleId: this.item.id,
            endpoint: {
              name: endpoint.name
            },
            post: false,
            get: false,
            put: false,
            delete: false
          });
        }
      }
      return endpoints;
    }
  },
  mounted() {
    this.apiSendGraph("endpoints", "id,name", "name").then(data => {
      this.allEndpoints = data.data;
    });
  }
};
</script>
